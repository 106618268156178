import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="About" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            How I Photograph
          </h2>
          <h3 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            <i>How I Work and What You Can Expect</i>
          </h3>
          <p>
            I am a photographer based in Frýdek-Místek, the Czech Republic. I
            specialize mainly in <b>concert</b>, <b>event</b>, and{" "}
            <b>portrait</b> photography. I have a passion for traveling, so
            coming directly to you is not an issue.
            <p>
              <h4>
                <b>Naturalness</b>
              </h4>
              <p>
                In photography, I appreciate <b>naturalness</b> and{" "}
                <b>authenticity</b>.{" "}
              </p>
              <p>
                In portraits, I'm trying to avoid staged, static poses and
                prefer capturing spontaneous movement, such as walking or subtle
                body gestures. I will <b>guide you through the photo session</b>
                . You don't have to come up with anything on the spot.
              </p>
              <p>
                I like to capture the essence of concerts and events - the{" "}
                <b>atmosphere</b> and <b>emotions</b>, and how both the
                performers and the audience are enjoying it.
              </p>
            </p>
            <p>
              <h4>
                <b>Equipment</b>
              </h4>
              <p>
                I use a <b>full-frame Nikon D780 DSLR</b>, which I combine with{" "}
                <b>prime lenses</b> to achieve the best possible quality even in
                challenging lighting conditions.
              </p>
              The camera body features <b>dual memory card slots</b>, so in case
              one card malfunctions, the photos are backed up on the second
              card. As a <b>backup body</b>, I use an APS-C DSLR.
            </p>
            <p>
              I also have additional accessories available, such as Godox
              speedlites, a LED pocket light, light modifiers, and creative
              filters.
            </p>
            <p>
              <h4>
                <b>Saving Your Time</b>
              </h4>
              <p>
                I'm committed to <b>making the most of our time together</b>.
                With a clear plan set beforehand, you can focus on being
                yourself while I manage the details.{" "}
              </p>
              <p>
                When delivering outputs, you will receive not only{" "}
                <b>high-quality</b> photos, but also <b>web-quality</b>{" "}
                versions.{" "}
              </p>
              <p>
                For Concerts & Events and Lens on the Band, I'm sending the
                first 10 photos in 24 hours. You will also receive these ones in
                a format suitable for posting on <b>Instagram</b> (posts +
                stories).
              </p>
              <p>
                The photos will be available to you on a <b>cloud storage</b>{" "}
                for one year.
              </p>
            </p>
          </p>

          <h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            Jak fotím
          </h2>
          <h3 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            <i>Jak pracuju a co ode mě můžete očekávat</i>
          </h3>
          <p>
            Jsem fotografka pocházející z Frýdku-Místku, z Moravskoslezského
            kraje. Zaměřuji se především na focení <b>koncertů</b>,{" "}
            <b>eventů</b> a <b>portrét</b>. Ráda a často cestuji, proto není
            vůbec žádný problém dorazit přímo za Vámi.
            <p>
              <h4>
                <b>Přirozenost</b>
              </h4>
              <p>
                Ve fotografii mám ráda <b>přirozenost</b> a <b>autentičnost</b>.
              </p>
              <p>
                U portrétu se snažím vyhýbám strojeným statickým pózám a raději
                upřednostňuju zachycení spontánního pohybu, jako je například
                chůze, nebo jemné pohyby těla.
                <b> Focením Vás provedu.</b> Nemusíte nic vymýšlet sami na
                místě.
              </p>
              <p>
                U koncertů a eventů ráda zachycuju hlavně esenci akce - její{" "}
                <b>atmosféru</b> a <b>emoce</b> a to, jak si ji užívají jak
                účinkující, tak publikum.
              </p>
            </p>
            <p>
              <h4>
                <b>Technika</b>
              </h4>
              <p>
                Používám <b>full frame zrcadlovku Nikon D780</b>, kterou
                kombinuju se <b>světelnými objektivy</b> s pevným ohniskem,
                abych docílila co nejlepší kvality i v nepříznivých světelných
                podmínkách.{" "}
              </p>
              <p>
                Tělo využívá <b>dva sloty na paměťové karty</b>, takže v případě
                poruchy jedné karty se fotografie zálohují na kartu druhou. Jako{" "}
                <b>záložní tělo</b> využívám APS-C zrcadlovku.
              </p>
              <p>
                Mám k dispozici i další příslušenství, jako například malé
                systémové blesky Godox, kompaktní LED světlo, modifikátory
                světla a kreativní filtry.
              </p>
            </p>
            <p>
              <h4>
                <b>Šetřím Váš čas</b>
              </h4>
              <p>
                Chci, abychom společný <b>čas využili na maximum</b>. Vše je
                pečlivě naplánováno, abyste se mohli uvolnit a být sami sebou,
                zatímco já se postarám o všechny detaily.
              </p>
              <p>
                Při předání výstupů dostanete nejen fotografie v{" "}
                <b>plné kvalitě</b>, ale také ve <b>webové kvalitě</b>.
              </p>
              <p>
                U koncertů, událostí a Lens on the Band zasílám prvních 10
                fotografií do 24 hodin. Pro ně platí, že je dostanete také ve
                formátu určenému k postování na <b>Instagram</b> (posty +
                stories).
              </p>
              <p>
                Fotografie jsou Vám k dispozici na <b>cloudovém úložišti</b> po
                dobu 1 roku.
              </p>{" "}
            </p>
          </p>
          <figure className="kg-card kg-image-card">
            <Img
              fluid={data.benchAccounting.childImageSharp.fluid}
              className="kg-image"
            />
          </figure>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(relativePath: { eq: "aneta_jursova_me.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
